<template>
  <v-app id="inspire">
    <!-- ヘッダー -->
    <MainHeader />
    <!-- ナビゲーションタブ -->
    <v-main>
      <v-tabs color="grey-darken-2" centered>
        <v-spacer />
        <v-tab
          class="px-3"
          density="compact"
          flat
          v-for="link in links"
          :key="link.path"
          :to="link.path"
          >{{ link.title }}</v-tab>
        <v-spacer />
        <v-tabs-slider color="orange" />
      </v-tabs>
      
      <!-- メインコンテンツ -->
      <router-view />
    </v-main>
    
    <!-- フッター -->
    <MainFooter /> <!-- カスタムフッターを使用 -->
  </v-app>
</template>

<script>
import { onMounted, ref } from "vue";
import MainFooter from "@/components/MainFooter.vue";
import MainHeader from "@/components/MainHeader.vue";

export default {
  components: {
    MainFooter,
    MainHeader,
  },
  setup() {
    const links = ref([]);
    onMounted(async () => {
      links.value = [
        { title: "Home", path: "/" },
        { title: "Profile", path: "/profile" },
        { title: "Notice", path: "/notice" },
        { title: "Product", path: "/products" },
      ];
    });

    return { links };
  },
};
</script>

<style>
.v-main {
  padding-top: 10px; /* ヘッダーの高さに応じて余白を追加 */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
