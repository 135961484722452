<template>
  <section class="topTaimen">
    <div class="wrap">
      <div class="taimenBox">
        <div class="taimenBox__left">
          <h2>
            <div>こんな時は...
            </div>
          </h2>
          <div class="taimenBox2">
            <ul class="taimenBox2__list">
              <li>● さかなは苦手…</li>
              <li>● さかながいるのが不安…</li>
              <li>● さかなはこわい…</li>
            </ul>
          </div>
        </div>
        <div class="taimenBox__right">
          <a href="/">
            <img
              src="@/assets/flutter-logo-sharing.png"
              alt="ねこ"
            />
          </a>
          <p class="taimenText01">※どんなさかなにも対応！</p>
        </div>
      </div>
    </div>
  </section>
</template>


<style scoped>
h1, h2, h3, h4 {
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.1em;
}
.topTaimen {
    padding: 100px 0 0 0;
}
.wrap {
    width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}
.taimenBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #EEEEEE;
    border: 6px solid #0092E5;
    border-radius: 20px;
    padding: 30px;
}
.taimenBox__left {
    max-width: 600px;
    width: 100%;
    padding: 20px 0 0 20px;
}
.taimenBox__right {
    max-width: 512px;
    width: 100%;
}
.taimenBox2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.taimenBox2__list {
    width: 400px;
    margin-top: 20px;
}
.taimenBox2__image {
    width: 160px;
}
.topTaimen h2 img {
    max-width: 356px;
    width: 100%;
}
img {
    max-width: 100%;
    height: auto;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: start;
}
.taimenBox2__list li {
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding-left: 1em;
    text-indent: -1em;
}

@media (max-width: 767px) {
    .wrap, .wrap1000, .wrap800 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
        .taimenBox {
        padding: 30px 16px;
    }
        .taimenBox__left {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
    }

}

@media (max-width: 430px) {
    .taimenBox {
        padding: 20px 10px;
        border-width: 3px;
    }
    .taimenBox__left, .taimenBox__right {
        padding: 0;
        margin: 0;
    }
    .taimenBox2__list {
        font-size: 1rem;
    }
    .taimenBox2__list li {
        font-size: 1rem;
        margin-bottom: 10px;
    }
}
</style>