import { createRouter, createWebHistory } from 'vue-router';
import HomePosts from '@/components/HomePosts.vue';
import ProfilePage from '@/components/ProfilePage.vue';
import PostsDetail from '@/components/PostsDetail.vue';
import NotAuth from '@/components/NotAuth.vue';
import NoticePage from '@/components/NoticePage.vue';
import NoticeDetail from '@/components/NoticeDetail.vue';
import AdminPage from '@/components/AdminPage.vue';
import ProductPage from '@/components/ProductPage.vue';
import ProductDetail from '@/components/ProductDetail.vue';
import MarkDownEditor from '@/components/MarkDownEditor.vue';
import { useCurrentUser } from 'vuefire';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePosts,
    meta: {
      breadcrumbs: [{ name: 'Home', href: '/' }],
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      breadcrumbs: [{ name: 'Profile', href: '/profile' }],
    }
  },
  {
    path: '/_post/:id',
    redirect: to => {
      console.log('Redirecting from:', to.fullPath);
      return `/post/${to.params.id}`;
    }
  },
  {
    path: '/post/:id',
    name: 'postDetail',
    component: PostsDetail,
    meta: {
      breadcrumbs: [{ name: 'Home', href: '/' }],
    }
  },
  {
    path: '/notAuth',
    name: 'notAuthenticationError',
    component: NotAuth
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticePage,
    meta: {
      breadcrumbs: [{ name: 'Notice', href: '/notice' }],
    }
  },
  {
    path: '/notice/:id',
    name: 'noticeDetail',
    component: NoticeDetail,
    meta: {
      breadcrumbs: [{ name: 'Home', href: '/' }],
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/post/:id',
    name: 'admin-postDetail',
    component: PostsDetail,
    meta: {
      breadcrumbs: [{ name: 'Home', href: '/' }],
    }
  },
  {
    path: '/products',
    name: 'products',
    component: ProductPage,
    meta: {
      breadcrumbs: [{ name: 'Product', href: '/products' }],
    }
  },
  {
    path: '/product/:id',
    name: 'productDetail',
    component: ProductDetail,
    meta: {
      breadcrumbs: [{ name: 'Home', href: '/' }],
    }
  },
  {
    path: '/edit/n/:id',
    name: 'editp',
    component: MarkDownEditor,
    meta: { requiresAuth: true, breadcrumbs: [{ name: 'Home', href: '/' }] }
  },
  {
    path: '/edit/p/:id',
    name: 'editn',
    component: MarkDownEditor,
    meta: { requiresAuth: true, breadcrumbs: [{ name: 'Home', href: '/' }] }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = useCurrentUser().value;

  console.log('Navigating to:', to.path);
  console.log('Current user:', currentUser);

  if (to.meta.requiresAuth && !currentUser) {
    console.log('Redirecting to login');
    next('/notAuth');
  } else {
    console.log('Navigation allowed');
    next();
  }
});

export default router;
