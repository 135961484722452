<template>
  <ol class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
    <!-- 各アイテムを動的に生成 -->
    <li
      v-for="(crumb, index) in breadcrumbs"
      :key="index"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
    >
      <a :itemprop="item" :href="crumb.href">
        <span class="breadcrumb-text-limited" itemprop="name">{{ crumb.name }}</span>
      </a>
      <meta :itemprop="position" :content="index + 1" />
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>