<template>
  <section id="menuCan" class="wrap can">
    <h2>比較表</h2>
    <div class="wrap1000">
      <div class="js-scrollable tblScroll">
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>当サイト</th>
              <th>さかな</th>
              <th>ねこ</th>
              <th>こんにゃく</th>
            </tr>
            <tr>
              <td>こんにゃく</td>
              <td>●</td>
              <td>●</td>
              <td>●</td>
              <td>✕</td>
            </tr>

            <tr>
              <td>人参</td>
              <td>✕</td>

              <td>▲ <span class="saiban">※1</span></td>
              <td>●</td>
              <td>✕</td>
            </tr>

            <tr class="canTblPri">
              <td>価格</td>
              <td class="canTblBtm">2円<br /></td>
              <td>3円前後</td>
              <td>6円前後</td>
              <td>8円前後</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="saibanTxt">※個体によります</p>
    </div>
  </section>
</template>

<style scoped>
h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.1em;
}
.can {
  background: #f0f3f7;
  border-radius: 20px;
  margin-top: 120px;
  padding: 105px 0 130px;
}
.wrap {
  width: 100%;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.wrap1000 {
  width: 100%;
  max-width: 1040px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.tblScroll {
  overflow-x: auto;
}
.can table {
  width: 100%;
  border-collapse: collapse;
}
.can table th:first-child {
  width: 220px;
  background-color: #f0f3f7;
}
.can table td {
  text-align: center;
  color: #0092e5;
  font-size: 1.6rem;
  padding: 16px;
}
.can table th {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5em;
  padding: 20px;
  vertical-align: middle;
}
.can table td:nth-child(2n) {
  background: #fff;
}
.can table th:nth-child(2) {
  border-top: 3px solid #0092e5;
  border-left: 3px solid #0092e5;
  border-right: 3px solid #0092e5;
}
.can table td:first-child {
  font-size: 1.6rem;
  color: #000;
  background-color: #f0f3f7;
  text-align: left;
}
.can table td:nth-child(2) {
  border-left: 3px solid #0092e5;
  border-right: 3px solid #0092e5;
}
.canTblPri td {
  font-size: 1.4rem !important;
  line-height: 1.5em;
  padding: 20px 0;
}
.canTblBtm {
  border-bottom: 3px solid #0092e5;
}
.saibanTxt {
  margin-top: 20px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .tblScroll {
    overflow-x: scroll;
  }
  .can table {
    width: 700px; /* 適切な幅に調整 */
  }
  .can table th,
  .can table td {
    font-size: 1.2rem;
    padding: 10px;
  }
  .can table td:first-child {
    font-size: 1.2rem;
  }
  .canTblPri td {
    font-size: 1.2rem !important;
  }
  .canTblPri td {
    padding: 10px 0;
  }
}
</style>

<script>
</script>
