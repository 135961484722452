import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { VueFire, VueFireAuth } from 'vuefire'
import "./assets/main.css";
import "./firebase/firebase";
import { firebaseApp } from './firebase/firebase'
import vuetify from "../plugins/vuetify";
import 'mavon-editor/dist/css/index.css'
import '@fortawesome/fontawesome-free/css/all.css';
import VueAdsense from 'vue-adsense'
const app = createApp(App);

app.use(router);
app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
  modules: [
    // we will see other modules later on
    VueFireAuth(),
  ],
});
app.component('ad-component', VueAdsense);
app.use(vuetify).mount("#app");