// firebase_function.js
import app from "@/firebase/firebase"; // 初期化された Firebase アプリをインポート
import { getFirestore, collection, doc, addDoc, getDocs, getDoc, query, where } from "firebase/firestore";

// Firestore インスタンスの初期化
const db = getFirestore(app); // 初期化された Firebase アプリを使用

// ブログポストを取得する関数
export const getAllPosts = async () => {
    const querySnapshot = await getDocs(collection(db, "posts"));
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getPostsNoArchive = async () => {
  const q = query(collection(db, "posts"), where("archive_flag", "==", false)); // クエリを作成
  const querySnapshot = await getDocs(q); // クエリを実行してスナップショットを取得
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// 指定された ID のブログポストを取得する関数
export const getPostById = async (id) => {
  const docRef = doc(db, "posts", id); // ID を使ってドキュメント参照を生成
  const docSnapshot = await getDoc(docRef); // ドキュメントを取得

  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() }; // ドキュメントのデータを返す
  } else {
    throw new Error(`Post with ID ${id} not found`); // ドキュメントが存在しない場合のエラーハンドリング
  }
};

// 指定された ID のブログポストを取得する関数
export const getPostByIdNoArchive = async (id) => {
  const docRef = doc(db, "posts", id); // ID を使ってドキュメント参照を生成
  const docSnapshot = await getDoc(docRef); // ドキュメントを取得

  if (docSnapshot.exists()) {
    // ドキュメントが存在する場合、archive_flag を確認して取得するかどうかを判断する
    const postData = docSnapshot.data();
    if (!postData.archive_flag) {
      return { id: docSnapshot.id, ...postData }; // archive_flag が false の場合、ドキュメントのデータを返す
    } else {
      throw new Error(`Post with ID ${id} is archived`); // archive_flag が true の場合、エラーをスローする
    }
  } else {
    throw new Error(`Post with ID ${id} not found`); // ドキュメントが存在しない場合のエラーハンドリング
  }
};


export const getAllNotices = async () => {
  const querySnapshot = await getDocs(collection(db, "notices"));
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getNoticesNoArchive = async () => {
  const q = query(collection(db, "notices"), where("archive_flag", "==", false)); // クエリを作成
  const querySnapshot = await getDocs(q); // クエリを実行してスナップショットを取得
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getNoticeById = async (id) => {
  const docRef = doc(db, "notices", id); // ID を使ってドキュメント参照を生成
  const docSnapshot = await getDoc(docRef); // ドキュメントを取得

  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ...docSnapshot.data() }; // ドキュメントのデータを返す
  } else {
    throw new Error(`Notifications with ID ${id} not found`); // ドキュメントが存在しない場合のエラーハンドリング
  }
};

export const getNoticeByIdNoArchive = async (id) => {
  const docRef = doc(db, "notices", id); // ID を使ってドキュメント参照を生成
  const docSnapshot = await getDoc(docRef); // ドキュメントを取得

  if (docSnapshot.exists()) {
    // ドキュメントが存在する場合、archive_flag を確認して取得するかどうかを判断する
    const noticeData = docSnapshot.data();
    if (!noticeData.archive_flag) {
      return { id: docSnapshot.id, ...noticeData }; // archive_flag が false の場合、ドキュメントのデータを返す
    } else {
      throw new Error(`Notice with ID ${id} is archived`); // archive_flag が true の場合、エラーをスローする
    }
  } else {
    throw new Error(`Notice with ID ${id} not found`); // ドキュメントが存在しない場合のエラーハンドリング
  }
};


// 新しいブログポストを作成する関数
export const createPost = async (post) => {
    return await addDoc(collection(db, "posts"), post);
};

export const createNotice = async (notice) => {
  return await addDoc(collection(db, "notices"), notice);
};

export const formatTimeStamp = (timestamp) => {
  if (!timestamp) return '';
  const { seconds, nanoseconds } = timestamp;
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);
  return date.toLocaleString();
};