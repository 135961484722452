<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <!-- メインコンテンツ -->
      <v-container fluid>
        <v-row class="center-align">
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
              <div class="my-5" />
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <div class="my-5" />
              <!-- サイドバー内容 -->
              <div>お知らせ</div>
              <v-list>
                <v-list-item
                  v-for="(notification, index) in notifications"
                  :key="index"
                  @click="selectNotification(notification)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>
          <!-- 右側のコンテンツ -->
          <v-col cols="12" md="9">
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <div class="my-5" />
              <div v-if="selectedNotification"><a
                  :href="`/notice/${selectedNotification.id}`"
                  style="background: rgb(255, 255, 255)"
                >
                <h2>{{ selectedNotification.title }}</h2></a>
              </div>
              <div v-else>
                <p>通知を選択してください</p>
              </div><div class="my-5" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import { getAllNotices, getNoticesNoArchive } from "@/firebase/firebase_function";

export default {
  props: ['admin'],
  components: {
    CustomBreadcrumb,
  },
  setup(props) {
    const admin = ref(props.admin);
    const notifications = ref([]);
    const selectedNotification = ref(null);

    const selectNotification = (notification) => {
      selectedNotification.value = notification;
    };

    const fetchNotifications = async () => {
      try {
      notifications.value = (admin.value)?await getAllNotices():await getNoticesNoArchive();
  } catch (error) {
    console.error("通知の取得中にエラーが発生しました:", error);
  }
    };

    onMounted(() => {
      fetchNotifications();
    });
    
    return {
      notifications,
      selectedNotification,
      selectNotification,
    };
  },
  computed: {
    breadcrumbs() {
      const route = useRoute();
      return route.meta.breadcrumbs || [];
    },
  },
};
</script>

<style>
</style>