<template>
  <ad-component
    width="100vw"
    height="320"
    type="adsense"
    data-ad-client="ca-pub-2733710251115604"
    data-ad-slot="3487017855"
    data-auto-format="rspv"
    data-full-width=""
  >
    <div overflow=""></div>
  </ad-component>
</template>

<script setup>
</script>

<style scoped>
.ad-placeholder {
  border: 1px solid #ccc; /* 境界線を追加 */
  padding: 20px; /* パディングを追加 */
  text-align: center; /* テキストを中央揃え */
  margin: 10px 0; /* 上下に余白を追加 */
}

.ad-content {
  background-color: #f9f9f9; /* 背景色を追加 */
  padding: 10px; /* パディングを追加 */
}

.ad-content img {
  width: 100%; /* 画像の幅を100%に設定 */
  height: auto; /* 高さを自動調整 */
}
</style>
