<!-- AppFooter.vue -->
<template>
  <v-footer class="app-footer">
    <v-container fluid>
      <v-row>
        <v-col class="text-center">
          <p>© 2024 monocraft-dev. All rights reserved.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped>
.app-footer {
  background-color: #999999; /* フッターの背景色 */
  color: white; /* フッターの文字色 */
  padding: 20px 0; /* フッターのパディング */
  text-align: center; /* テキストを中央揃え */
}
</style>
