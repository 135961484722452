<template>
  <aside v-if="isVisible" class="pop" :style="{ visibility: isVisible ? 'visible' : 'hidden', opacity: isVisible ? 1 : 0 }">
    <div class="block left">
      <p class="txt"><span class="red-a">お問い合わせ</span>はこちらから</p>
      <div class="box">
        <img class="icon" src="../assets/x.png" alt="X" />
        <img class="icon" src="../assets/Instagram.png" alt="Instagram" />
        <img class="icon" src="../assets/line.png" alt="LINE" />
      </div>
    </div>
    <div class="block right">
      <p class="txt">
        お気軽に<br />ご連絡<br />ください
      </p>
      <div class="delete-btn" @click="closeModal">
        <img
          src="../assets/popup-close.png"
          alt="非表示"
        />
      </div>
    </div>
  </aside>
</template>


<script>
export default {
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.pop {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 50px;
  width: 500px;
  height: 136px;
  box-shadow: 0 0 8px 1px #ddd;
  z-index: 9999;
  transition: opacity 0.5s ease-in-out, width 0.5s ease-in-out;
  margin: 15px 0;
}
.pop .left {
  border-top: solid 3px;
  border-left: solid 3px;
  border-bottom: solid 3px;
  width: 420px;
  padding: 23px 20px 0;
  background: #fff;
  transition: width 0.5s ease-in-out;
}
.pop .right {
  border: solid 3px;
  position: relative;
  width: 180px;
  padding: 25px 10px 10px 20px;
  background: #ffd700;
  transition: width 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pop .right .txt {
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.6;
  letter-spacing: .08em;
  margin-bottom: 15px;
}
.pop .left .txt {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.1em;
  text-align: center;
}
.pop .left .box {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.icon {
  width: 30px;
  height: 30px;
}
img {
  max-width: 100%;
  height: auto;
  border: 0;
  -webkit-backface-visibility: hidden;
}
.pop .right .delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -17px;
  right: -17px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #02a7d0;
  cursor: pointer;
  user-select: none;
}
.red-a {
  color: #fc2929;
}
@media (max-width: 400px) {
  .pop {
    bottom: 0px;
    right: 20px;
    width: calc(100% - 40px);
    height: auto;
  }
  .pop .left {
    width: 60%;
  }
  .pop .right {
    width: 40%;
  }
  .pop .right .txt {
    font-size: 12px;
  }
  .pop .left .txt {
    font-size: 12px;
  }
  .pop .right .delete-btn {
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
  }
}
</style>
