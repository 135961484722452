<template>
  <v-app id="inspire">
    <v-main class="bg-grey-lighten-3">
      <!-- パンくずリスト -->
      <CustomBreadcrumb :breadcrumbs="breadcrumbs" />
      <v-container fluid>
        <v-row class="center-align">
          <!-- 中央揃えクラスを削除 -->
          <!-- 左側のサイドバー -->
          <v-col cols="12" md="3">
            <v-sheet
              rounded="lg"
              :style="{
                maxHeight: '1000px',
                overflow: 'auto',
              }"
            >
              <div class="my-5" />
              <h3>アクセス権がありません</h3>
              <AuthWidget
                :user="user"
                :signinPopup="signinPopup"
                :logout="logout"
              />
              <div class="my-5" />
            </v-sheet>
          </v-col>

          <!-- メインコンテンツ -->
          <v-col cols="12" md="8" class="main-content">
            <section class="container-vertical-layout">
              <div class="not-auth">
                <p>このページにアクセスするには</p>
                <p>管理者権限が必要です。</p>
              </div>
            </section>
          </v-col>

          <!-- 右側のサイドバー -->
          <v-col cols="12" md="3">
            <!-- 広告コンテンツ -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { useRoute } from "vue-router";
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue";
import AuthWidget from "@/components/AuthWidget.vue";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useCurrentUser, useFirebaseAuth } from "vuefire";
import { signOut } from "firebase/auth";
import router from "@/router";

export default {
  components: {
    CustomBreadcrumb,
    AuthWidget,
  },
  computed: {
    breadcrumbs() {
      const route = useRoute();
      return route.meta.breadcrumbs || [];
    },
  },
  setup() {
    const auth = useFirebaseAuth();
    const googleAuthProvider = new GoogleAuthProvider();
    const signinPopup = async () => {
      await signInWithPopup(auth, googleAuthProvider);
      router.push(`/edit/p`);
    };
    const user = useCurrentUser();
    // ログアウト関数
    const logout = async () => {
      try {
        await signOut(auth); // Firebase Authでログアウト
        alert("ログアウトしました");
      } catch (error) {
        alert("ログアウト時にエラーが発生しました:", error);
      }
    };
    return { user, signinPopup, logout };
  },
};
</script>

<style scoped>
.main-content {
  display: flex; /* Flexboxを使う */
  flex-direction: column; /* 縦方向に要素を並べる */
  justify-content: center; /* 縦方向に中央揃え */
  align-items: center; /* 横方向に中央揃え */
  height: 100%; /* コンテンツを親要素に合わせる */
}
</style>
